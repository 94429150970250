.slider-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
}

.slide::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;

  z-index: 10;

  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
}

/* .previousButton {
    left: 0;
    -webkit-transform: rotate(0deg) ;
    
  } */

.nextButton {
  right: 0;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content * {
  pointer-events: none;
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 48px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

.slider-wrapper,
.slide {
  height: 456px;
}
