.shipping_method {
  @apply w-full md:flex p-4 mb-2  font-inter;
}

.shipping_method_selected {
  @apply w-full p-4 md:flex mb-2 font-inter;
}
.payment-method-item-wrapper {
  @apply w-full;
}
/* .payment-method-item-wrapper.active {
  @apply !border-tinBlue !bg-bgWhite;
}
.brand-primaryBlue{
  @apply text-tinBlue;
} */
.brand-gray {
  @apply text-bgWhite;
}
/* .MuiButtonBase-root{
    @apply !p-0
} */
