.cart-page-wrapper {
  @apply w-full md:pt-60 pt-24 md:px-24 px-[16.5px] md:pb-24 pb-12 font-inter;
}
.cart-page-content {
  @apply mx-auto grid grid-cols-1 md:gap-8 gap-8;
}
.cart-action-content {
  @apply gap-6 text-[14px] leading-[18px] font-semibold text-dodgerBlue;
}
.cart-head-item {
  @apply font-inter !font-bold !text-[14px] !leading-[20px];
}
.cart-row-item {
  @apply !py-6 !border-none;
}

.quantity-wrapper {
  @apply w-[68px];
}
.cart-product-info-wrapper {
  @apply !w-[356px];
}
.cart-action-panel-wrapper {
  @apply md:w-[384px] w-full;
}

.cart-product-wrapper {
  position: relative;
}

.cart-product-wrapper-btn {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: #fe6964;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.cart-page-content .MuiTableCell-head {
  border-bottom: none;
  color: #8e9099;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.shopping-cart_table-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 16px 0 16px 16px;
  text-transform: capitalize;
}

.shopping-cart_promotions-label {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 8px;
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  width: auto;
  height: 20px;
}
